import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`xid`}</h1>
    <ul>
      <li parentName="ul">{`Sometimes, the `}<a parentName="li" {...{
          "href": "/owner",
          "title": "owner"
        }}>{`owner`}</a>{` of a `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` has some existing linkage to the identity of their `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{`, ie., they are sending out an email campaign or people are participating behind a login wall where the conversation is embedded`}</li>
      <li parentName="ul">{`A note: using `}<a parentName="li" {...{
          "href": "/xid",
          "title": "xid"
        }}>{`xid`}</a>{` assumes that the `}<a parentName="li" {...{
          "href": "/owner",
          "title": "owner"
        }}>{`owner`}</a>{` has the token, this is different from `}<a parentName="li" {...{
          "href": "/creating-single-use-urls",
          "title": "creating single use urls"
        }}>{`creating single use urls`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/xid",
          "title": "xid"
        }}>{`xid`}</a>{` works in the embedded case — ie., the `}<a parentName="li" {...{
          "href": "/owner",
          "title": "owner"
        }}>{`owner`}</a>{` has added the `}<a parentName="li" {...{
          "href": "/embed-code",
          "title": "embed code"
        }}>{`embed code`}</a>{` to a page on their own web property`}</li>
      <li parentName="ul">{`Once the `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` has been embedded on a third party webpage, that page can, however it likes, via JavaScript or via templating for instance, add the data attribute `}<inlineCode parentName="li">{`data-xid="test"`}</inlineCode></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`xid`}</inlineCode>{` value for each participant will be available on the participation record in the `}<a parentName="li" {...{
          "href": "/export",
          "title": "export"
        }}>{`export`}</a>{``}</li>
      <li parentName="ul">
        <h3 parentName="li">{`Example`}</h3>
        <ul parentName="li">
          <li parentName="ul">{`A common workflow for using `}<inlineCode parentName="li">{`xid`}</inlineCode>{` involves a table of demographic data available from a polling provider`}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/Participants",
              "title": "Participants"
            }}>{`Participants`}</a>{` are sent an email and invited to participate`}</li>
          <li parentName="ul">{`Then, when the `}<a parentName="li" {...{
              "href": "/participant",
              "title": "participant"
            }}>{`participant`}</a>{` clicks through the email to a custom url, custom JavaScript written by whoever is controlling the third party website on which polis is embedded grabs a token out of the url and adds it to the `}<inlineCode parentName="li">{`data-xid="someTokenFromTheURLBarThatIdentifiesTheUser"`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`Embed code parameter that allows login-less participation by known users`}</li>
      <li parentName="ul">{`Usage: `}<inlineCode parentName="li">{`data-xid="guid"`}</inlineCode>{` or `}<inlineCode parentName="li">{`data-xid="5647434556754623"`}</inlineCode>{` or less anonymously and not recommended `}<inlineCode parentName="li">{`data-xid="foo@bar.com"`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      